import React, { useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";

const PdfComponent = ({ id, title }) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <Card sx={{ backgroundColor: palette.warning.main }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          </CardContent>
          <CardActionArea onClick={handleClickOpen}>
            <div className="square-thumbnail-wrapper">
              <img
                src={`https://drive.google.com/thumbnail?id=${id}&sz=w800`}
                alt={title}
                className="pdf-thumbnail-image"
              />
            </div>
          </CardActionArea>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogActions>
          <Box flex={1} px={2}>
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          </Box>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogActions>

        <Box height={"100vh"}>
          <iframe
            src={`https://drive.google.com/file/d/${id}/preview`}
            title={title}
            width="100%"
            height="100%"
            style={{ border: "none", flex: 1 }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default PdfComponent;
