import React from "react";
import { Button, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router";

const AgremmentsButtons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <Grid container>
        <Grid container item md={4}>
          <Button
            onClick={() => {
              navigate(ROUTES.PRIVACY);
            }}
            fullWidth
          >
            {t("app.privacyPolicy.title")}
          </Button>
        </Grid>
        <Grid container item md={4}>
          <Button
            onClick={() => {
              navigate(ROUTES.TERMS);
            }}
            fullWidth
          >
            {t("app.termsOfUse.title")}
          </Button>
        </Grid>
        <Grid container item md={4}>
          <Button
            onClick={() => {
              navigate(ROUTES.EULA);
            }}
            fullWidth
          >
            {t("app.endUserLicenseAgreement.title")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AgremmentsButtons;
