import React from "react";
import { Box, Container, Paper, useTheme } from "@mui/material";
import Contact from "./Contact";
import roseTopLeft from "../../assets/roses/left-top-min.png";
import roseBottomRight from "../../assets/roses/right-bottom-min.png";

const ContactAndCareers = () => {
  const theme = useTheme();

  return (
    <Box
      id="contact"
      sx={{
        background: `linear-gradient(180deg, ${
          theme.palette.primary.main
        } 0%, ${"#00322b"} 100%)`,
      }}
    >
      <Container
        sx={{
          padding: 2,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Paper
          elevation={6}
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: "100%",
            marginTop: "70px",
            position: "relative",
            maxWidth: 800,
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              margin: 2,
              padding: 2,
            }}
          >
            <Contact />
          </Box>
          <Box
            position="absolute"
            top={0}
            left={0}
            component="img"
            src={roseTopLeft}
            alt="rose"
            width={100}
          />
          <Box
            position="absolute"
            bottom={0}
            right={0}
            component="img"
            src={roseBottomRight}
            alt="rose"
            width={100}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactAndCareers;
