import React from "react";
import { Avatar, Fab } from "@mui/material";
import { useTranslation } from "react-i18next";

const SpringBooksFab = () => {
  const { t } = useTranslation();

  const handleFab = () => {
    window.open(t("products.books.link"));
  };
  return (
    <Fab
      onClick={handleFab}
      color="secondary"
      sx={{
        position: "fixed",
        bottom: 190,
        left: 0,
        borderRadius: 0,
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
      }}
    >
      <Avatar alt="Remy Sharp" src={t("products.books.image")} />
    </Fab>
  );
};

export default SpringBooksFab;
