export const urlFromImageId = (id) =>
  `https://drive.google.com/thumbnail?id=${id}&sz=w800`;

export const appScreens = [
  urlFromImageId("1Mk45oHzcJ_E22w-zyio5zKRvI7Wx0Zc1"),
  urlFromImageId("1EnRpuQvKqRDKXhxZYuxLtAKEwYie3zEf"),
  urlFromImageId("1WNwzWvjn3Al_zTvJ8t28yM1_VsA2Qs8I"),
  urlFromImageId("1i6MZOaXmrm43fVGwvBh9yPNA1DdKabAD"),
  urlFromImageId("13557rAPIbeLX4x2eI8Oy7fZAvPtBB0iA"),
  urlFromImageId("1zkPYxelGdtCBOECIkMO5Fh0ZuUaLItQj"),
];

export const amazonBookImages = [
  "https://m.media-amazon.com/images/I/61sBqQ+XwjL._SL1500_.jpg",
  "https://m.media-amazon.com/images/I/61XQs4VqzVL.jpg",
];

export const flakesOfJoyBackground = urlFromImageId(
  "1-mfSBDcDtNYtzfHIGZLS4CC167n6rqEn"
);
