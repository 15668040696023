import React from "react";
import { Box, Grid } from "@mui/material";
import logo from "../../assets/logo-min.png";

const LogoAndContactInfo = () => {
  return (
    <Grid
      md={6}
      container
      item
      justifyContent="center"
      alignItems="center"
      sx={{ px: 1, pt: 10 }}
    >
      <Grid
        xs={12}
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Box
          component="img"
          src={logo}
          alt="logo"
          sx={{ width: "100%", maxWidth: 400, py: 2 }}
        />
      </Grid>
    </Grid>
  );
};

export default LogoAndContactInfo;
