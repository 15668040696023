import React from "react";
import { Card, CardMedia, Box, Container } from "@mui/material";
import Title from "./common/Title";
import { useTranslation } from "react-i18next";

const SpotifyPodcast = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box pt={10}>
        <Title>{t("podcast.title")}</Title>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Card sx={{ width: "100%", maxWidth: "100vw" }}>
          <CardMedia
            component="iframe"
            src="https://open.spotify.com/embed/show/7pvlH3csAnvsEdV6aPpH0K?utm_source=generator"
            height="232"
            title="Podcast Embed"
            style={{ border: "none", width: "100%" }}
          />
        </Card>
      </Box>
    </Container>
  );
};

export default SpotifyPodcast;
