import React from "react";
import { Box, Button, CardActions, CardMedia, Collapse } from "@mui/material";

import SubTitle from "../common/SubTitle";
import Body from "../common/Body";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CustomButton from "../common/CustomButton";
import RoseDivider from "../common/RoseDivider";
import { useTranslation } from "react-i18next";

const ServicesCard = ({ title, text, image, button, children, link }) => {
  const { t } = useTranslation();
  const [expand, setExpend] = React.useState(false);
  return (
    <Card sx={{ margin: 2, flex: 1, minWidth: { xs: 300, md: 400 } }}>
      <CardMedia
        sx={{ width: "100%", height: 300 }}
        image={image}
        title={title}
      />
      <CardContent>
        <SubTitle>{title}</SubTitle>
        <Box height={10} />
        <RoseDivider />
        <Box height={10} />
        <Body>{text}</Body>
        <Box height={10} />
        {children && (
          <>
            <Button
              fullWidth
              onClick={() => {
                setExpend((prev) => !prev);
              }}
            >
              <Body>
                {expand ? t("common.readLess") : t("common.readMore")}
              </Body>
            </Button>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              {children}
            </Collapse>
          </>
        )}
      </CardContent>
      <CardActions sx={{ padding: 5 }}>
        <CustomButton
          fullWidth
          size="large"
          onClick={(e) => {
            e.preventDefault();
            window.open(link);
          }}
        >
          <Body>{button}</Body>
        </CustomButton>
      </CardActions>
    </Card>
  );
};

export default ServicesCard;
