import React, { useEffect } from "react";
import BlankPage from "../components/BlankPage";
import Lottie from "lottie-react";
import NotFoundLottie from "../assets/notfound.json";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Title from "../components/common/Title";
import SubTitle from "../components/common/SubTitle";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("helmet.notFound")}</title>
      </Helmet>
      <BlankPage>
        <Container maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box my={5}>
            <Title>{t("notFound.title")}</Title>
            <SubTitle>{t("notFound.message")}</SubTitle>
            {/*<Body>*/}
            {/*  {t("notFound.message")}{" "}*/}
            {/*  <CustomLink>{t("notFound.message")}</CustomLink>*/}
            {/*</Body>*/}
          </Box>
          <Lottie animationData={NotFoundLottie} />
        </Container>
      </BlankPage>
    </>
  );
};

export default NotFound;
