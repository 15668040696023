import React from "react";
import { Container, Grid } from "@mui/material";
import PdfComponent from "./PdfComponent";

const PdfContainer = () => {
  return (
    <Container sx={{ paddingY: 2 }}>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <PdfComponent
          id={"1ejQbGNRYXNMn_WOIoUxmohObEyKXwrR5"}
          title={"נשות עסקים מצליחות 15.4.2024 - לאשה"}
        />
        <PdfComponent
          id={"1F4QChcDtb8emlM8ESP1m2qxA1E_U1llp"}
          title={"נשים בראש אחד 6.3.2013 - גל גפן"}
        />
        <PdfComponent
          id={"1lj19HQIvN11x6G7K2Dqk2wgZel2ExpSb"}
          title={"האביב של דאלי 8.9.2012 - מודיעין ניוז"}
        />
      </Grid>
    </Container>
  );
};

export default PdfContainer;
