import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

//lotties
import devices from "../../assets/lottie/devices.json";
import question from "../../assets/lottie/question.json";
import happy from "../../assets/lottie/happy.json";
import sad from "../../assets/lottie/sad.json";
import explore from "../../assets/lottie/explore.json";
import languages from "../../assets/lottie/languages.json";

import { SECTIONS } from "../../utils/constants";
import FeatureButton from "../common/FeatureButton";
import AgremmentsButtons from "./AgremmentsButtons";

const FeaturesList = () => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      md={6}
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
    >
      <Grid container item xs={12}>
        <AgremmentsButtons />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={devices}
          text={t("sections.multipleDevices.title")}
          section={`#${SECTIONS.DEVICES}`}
        />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={question}
          text={t("sections.questions.title")}
          section={`#${SECTIONS.QUESTIONS}`}
        />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={happy}
          text={t("sections.positive.title")}
          section={`#${SECTIONS.POSITIVE}`}
        />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={sad}
          text={t("sections.negative.title")}
          section={`#${SECTIONS.NEGATIVE}`}
        />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={explore}
          text={t("sections.explore.title")}
          section={`#${SECTIONS.EXPLORE}`}
        />
      </Grid>
      <Grid item xs={6} p={1}>
        <FeatureButton
          src={languages}
          text={t("sections.languages.title")}
          section={`#${SECTIONS.LANGUAGES}`}
        />
      </Grid>
    </Grid>
  );
};

export default FeaturesList;
