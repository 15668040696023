import React from "react";
import {
  Box,
  Container,
  Grow,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typewriter from "typewriter-effect";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";

import roseTopLeft from "../../assets/roses/left-top-min.png";
import roseBottomRight from "../../assets/roses/right-bottom-min.png";
import { FastForwardRounded, PlayArrowRounded } from "@mui/icons-material";

const WomenBlessingContainer = () => {
  const { width } = useWindowDimensions();

  const [skip, setSkip] = React.useState(false);

  const handleSkip = () => {
    setSkip((prev) => !prev);
  };

  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        padding: 2,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Paper
        elevation={6}
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          marginTop: "56px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.main,
            margin: 2,
            padding: 2,
          }}
        >
          <Card
            elevation={0}
            sx={{
              backgroundColor: theme.palette.warning.main,
              minHeight: 500,
            }}
          >
            <CardContent sx={{ color: theme.palette.primary.main }}>
              <Typography
                color={theme.palette.error.main}
                variant={width > 600 ? "h4" : "h6"}
                textAlign="center"
              >
                {t("welcomeSection.title")}
              </Typography>
              <Box height={10} />
              {skip ? (
                <Grow in={skip}>
                  <Typography
                    variant={width > 600 ? "h3" : "h5"}
                    textAlign="center"
                  >
                    {t("welcomeSection.womenBlessing1") +
                      t("welcomeSection.womenBlessing2")}
                  </Typography>
                </Grow>
              ) : (
                <Typography
                  variant={width > 600 ? "h3" : "h5"}
                  textAlign="center"
                >
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(t("welcomeSection.womenBlessing1"))
                        .typeString(t("welcomeSection.womenBlessing2"))
                        .callFunction(() => {
                          handleSkip();
                        })
                        .start();
                    }}
                  />
                </Typography>
              )}
              <Box height={10} />
            </CardContent>
          </Card>
        </Box>
        <Box
          textAlign="center"
          position="absolute"
          bottom={30}
          left={0}
          right={0}
          sx={{ margin: "0 auto" }}
        >
          <Typography variant="caption">
            {t("welcomeSection.credit")}
          </Typography>
        </Box>
        <Box
          position="absolute"
          top={0}
          left={0}
          component="img"
          src={roseTopLeft}
          alt="rose"
          width={100}
        />
        <Box
          position="absolute"
          bottom={0}
          right={0}
          component="img"
          src={roseBottomRight}
          alt="rose"
          width={100}
        />
        <IconButton
          color="primary"
          sx={{ position: "absolute", top: 40, right: 40 }}
          onClick={handleSkip}
        >
          {skip ? <PlayArrowRounded /> : <FastForwardRounded />}
        </IconButton>
      </Paper>
    </Container>
  );
};

export default WomenBlessingContainer;
