import React from "react";
import { Typography, useTheme } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { GENERAL } from "../../utils/constants";

const Title = ({ children, align = "center", color }) => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  return (
    <Typography
      variant={width > GENERAL.MAX_MOBILE_WIDTH ? "h2" : "h4"}
      gutterBottom
      style={{
        textAlign: align,
        margin: 0,
        color: color || theme.palette.primary.main,
      }}
    >
      {children}
    </Typography>
  );
};

export default Title;
