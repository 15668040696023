import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import CustomButton from "./common/CustomButton";
import logo from "../assets/logo-min.png";
import { GENERAL, LANGUAGES_ARR, ROUTES } from "../utils/constants";
import { useTranslation } from "react-i18next";
import { Squash as Hamburger } from "hamburger-react";
import {
  Language,
  MailOutline,
  PhoneIphoneRounded,
  SportsEsports,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import Body from "./common/Body";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const TopNav = (props) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (code) => {
    handleClose();
    i18n.changeLanguage(code);
    document.body.dir = i18n.dir();
  };

  const handleNavMenu = () => {
    setAnchorElNav(!anchorElNav);
    setBurgerNav(!burgerNav);
  };

  const PhoneButton = () => {
    return (
      <CustomButton
        variant="text"
        color="primary"
        fullWidth={true}
        onClick={() => {
          window.open(`tel:${t("header.phoneNumberLink")}`, "_self");
        }}
      >
        <Body>{t("header.phoneNumberText")}</Body>
        <PhoneIphoneRounded color="info" sx={{ mx: 1 }} />
      </CustomButton>
    );
  };

  const MailButton = () => {
    return (
      <CustomButton
        variant="text"
        color="primary"
        fullWidth={true}
        onClick={() => {
          window.open(`mailto:${t("header.mailLink")}`, "_self");
        }}
      >
        <Body>{t("header.mailLink")}</Body>
        <MailOutline color="info" sx={{ mx: 1 }} />
      </CustomButton>
    );
  };

  const GameButton = () => {
    return (
      <CustomButton
        variant="text"
        color="primary"
        fullWidth={true}
        onClick={() => {
          navigate(ROUTES.APP);
        }}
      >
        <Body>{GENERAL.APP.TITLE}</Body>
        <SportsEsports color="info" sx={{ mx: 1 }} />
      </CustomButton>
    );
  };

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar style={{ backgroundColor: theme.palette.background.default }}>
          <Container sx={{ px: 0 }}>
            <Toolbar sx={{ px: 1 }}>
              <CustomButton
                variant="text"
                color="primary"
                onClick={() => {
                  navigate(ROUTES.HOME);
                }}
              >
                <Avatar alt="logo" src={logo} />
                <Box width={5} />
                <Body>{t("header.logoText")}</Body>
              </CustomButton>
              <Box sx={{ flex: 1 }} />

              <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}>
                <PhoneButton />
                <MailButton />
                <GameButton />
              </Box>
              <>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Language />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {LANGUAGES_ARR.map((lng) => {
                    return (
                      <MenuItem
                        key={lng.VALUE}
                        onClick={() => {
                          handleChangeLanguage(lng.CODE);
                        }}
                      >
                        {lng.VALUE}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: theme.palette.secondary.main,
                }}
              >
                <Hamburger
                  toggled={burgerNav}
                  toggle={handleNavMenu}
                  size={20}
                  duration={0.2}
                ></Hamburger>
              </Box>
            </Toolbar>
            <Collapse in={anchorElNav} timeout="auto" unmountOnExit>
              <List>
                <ListItem sx={{ justifyContent: "center" }}>
                  <PhoneButton />
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <MailButton />
                </ListItem>
                <ListItem sx={{ justifyContent: "center" }}>
                  <GameButton />
                </ListItem>
              </List>
            </Collapse>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default TopNav;
