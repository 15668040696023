import React from "react";
import YoutubeCard from "./YoutubeCard";
import { Box, Container, Grid } from "@mui/material";
import Title from "../common/Title";
import { useTranslation } from "react-i18next";
import PdfContainer from "../pdf/PdfContainer";

const YoutubeContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box pt={10}>
        <Title>{t("tv.title")}</Title>
      </Box>
      <Container sx={{ paddingY: 2 }}>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <YoutubeCard
            videoId="wHElt4RcZhM"
            title="האם אפשר לפתח חוסן רגשי? - חיים טוב"
          />
          <YoutubeCard
            videoId="wtswNAHJw10"
            title="הכרת תודה וראיית הטוב ככלי לצמיחה ממשברים -  ערוץ 14"
          />
          <YoutubeCard
            videoId="x8hZE1YE9RQ"
            title="אהבה וחשיבה חיובית ככלי להתמודדות עם משבר - ערוץ 13 "
          />
          <YoutubeCard
            videoId="Tu01XHBoJ2g"
            title="צמיחה מפוסט טראומה - ערוץ 14"
          />
        </Grid>
      </Container>
      <PdfContainer />
    </>
  );
};

export default YoutubeContainer;
