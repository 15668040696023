import React from "react";
import { Box, Container } from "@mui/material";
import Title from "../common/Title";
import { useTranslation } from "react-i18next";
import ProductsCard from "./ProductsCard";
import Body from "../common/Body";
import { urlFromImageId } from "../../utils";

const ProductsContainer = () => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingY: 5,
        paddingX: 2,
      }}
    >
      <Box flexGrow={1}>
        <Title>{t("products.title")}</Title>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          flexGrow={1}
        >
          <ProductsCard
            title={t("products.books.title")}
            text={t("products.books.textMain")}
            button={t("products.books.button")}
            image={t("products.books.image")}
            link={t("products.books.link")}
          >
            <Box height={10} />
            <Body align="right">{t("products.books.textExpend")}</Body>
          </ProductsCard>
          <ProductsCard
            title={t("products.cards.title")}
            text={t("products.cards.textMain")}
            button={t("products.cards.button")}
            image={t("products.cards.image")}
            link={t("products.cards.link")}
          >
            {/*<Box height={10} />*/}
            {/*<Body align="right">{t("products.cards.textExpend1")}</Body>*/}
            {/*<Box height={10} />*/}
            {/*<Body align="right">{t("products.cards.textExpend2")} </Body>*/}
          </ProductsCard>
          <ProductsCard
            title={t("products.mrAnxiety.title")}
            text={t("products.mrAnxiety.textMain")}
            button={t("products.mrAnxiety.button")}
            image={t("products.mrAnxiety.image")}
            link={t("products.mrAnxiety.link")}
          >
            {/*<Box height={10} />*/}
            {/*<Body align="right">*/}
            {/*  {t("products.mrAnxiety.textExpend1")}*/}
            {/*</Body>*/}
            {/*<Box height={10} />*/}
            {/*<Body>*/}
            {/*  <strong>{t("products.mrAnxiety.textExpend2")}</strong>*/}
            {/*</Body>*/}
          </ProductsCard>
          <ProductsCard
            title={t("products.mrAnxietyApp.title")}
            text={t("products.mrAnxietyApp.textMain")}
            button={t("products.mrAnxietyApp.button")}
            image={urlFromImageId(t("products.mrAnxietyApp.image"))}
            link={t("products.mrAnxietyApp.link")}
          >
            {/*<Box height={10} />*/}
            {/*<Body align="right">*/}
            {/*  {t("products.mrAnxiety.textExpend1")}*/}
            {/*</Body>*/}
            {/*<Box height={10} />*/}
            {/*<Body>*/}
            {/*  <strong>{t("products.mrAnxiety.textExpend2")}</strong>*/}
            {/*</Body>*/}
          </ProductsCard>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductsContainer;
