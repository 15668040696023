import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import he from "./he.json";
import en from "./en.json";

i18next.use(initReactI18next).init({
  fallbackLng: "he",
  compatibilityJSON: "v3",
  resources: {
    he: he,
    en: en,
  },
});

export default i18next;
