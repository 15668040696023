import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Send, WhatsApp } from "@mui/icons-material";
import Title from "../common/Title";
import Body from "../common/Body";
import CustomButton from "../common/CustomButton";
import SubTitle from "../common/SubTitle";

const Contact = () => {
  const [values, setValues] = React.useState({
    name: "",
    tel: "",
    message: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const { t } = useTranslation();
  const theme = useTheme();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .send("service_u4g3zb9", "template_y85n8me", values, "cBvFv4Wb6IGr2Fqt9")
      .then(
        (result) => {
          console.log(result);
          setSuccess(true);
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setError(true);
          setLoading(false);
        }
      );
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.default,
      }}
      elevation={0}
      dir="rtl"
    >
      <CardContent sx={{ padding: { xs: 2, md: 5 } }}>
        <Box height={10} />
        <Title>{t("contact.title")}</Title>
        <Box height={50} />
        <CustomButton
          fullWidth
          color="success"
          size="large"
          onClick={() => {
            window.open(t("whatsapp.fab"));
          }}
        >
          <Body>{t("contact.whatsapp")}</Body>
          <Box width={5} />
          <WhatsApp />
        </CustomButton>
        <Box height={20} />
        <CustomButton
          fullWidth
          color="info"
          size="large"
          onClick={() => {
            window.open(`tel:${t("header.phoneNumberLink")}`, "_self");
          }}
        >
          <Body>{t("contact.call")}</Body>
          <Box width={5} />
        </CustomButton>
        <Box height={50} />
        {success && (
          <Alert severity="success">
            <SubTitle>{t("contact.form.success")}</SubTitle>
          </Alert>
        )}
        {error && <Alert severity="error">{t("contact.form.error")}</Alert>}
        {!success && !error && (
          <form onSubmit={sendEmail}>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="user_name">
                {t("contact.form.name")}
              </InputLabel>
              <OutlinedInput
                id="user_name"
                type="text"
                label={t("contact.form.name")}
                value={values.name}
                onChange={handleChange("name")}
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="tel">{t("contact.form.tel")}</InputLabel>
              <OutlinedInput
                id="tel"
                type="tel"
                label={t("contact.form.tel")}
                value={values.tel}
                onChange={handleChange("tel")}
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="message">
                {t("contact.form.message")}
              </InputLabel>
              <OutlinedInput
                id="message"
                type="message"
                multiline
                rows={5}
                label={t("contact.form.message")}
                value={values.message}
                onChange={handleChange("message")}
              />
            </FormControl>
            <Box height={20} />
            <CustomButton type={"submit"} fullWidth={true} size={"large"}>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  <Send />
                  <Box width={10} />
                  <Body>{t("contact.form.submit")}</Body>
                </>
              )}
            </CustomButton>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default Contact;
