import React from "react";
import { Box, Divider } from "@mui/material";
import rose from "../../assets/roses/rose-left-min.png";

const RoseDivider = () => {
  return (
    <Box>
      <Divider>
        <Box
          top={0}
          left={0}
          component="img"
          src={rose}
          alt="rose"
          width={30}
        />
      </Divider>
    </Box>
  );
};

export default RoseDivider;
