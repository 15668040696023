import React from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Paper,
  Grid,
  CardMedia,
} from "@mui/material";
import { flakesOfJoyBackground } from "../../utils";
import Title from "../common/Title";
import Card from "@mui/material/Card";

const CourseShowcase = () => {
  return (
    <Container>
      <Box pt={10}>
        <Title>פתיתים של שמחה - קורס דיגיטלי</Title>
      </Box>
      <Paper
        sx={{
          position: "relative",
          zIndex: 1,
          backgroundImage: `url(${flakesOfJoyBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: 600,
          display: "flex",
        }}
      >
        <Grid
          container
          sx={{
            backgroundColor: "rgba(255,255,255,0.5)",
            padding: 5,
            backdropFilter: " blur(10px)",
          }}
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            py={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: "bold",
                mb: 2,
                fontSize: { xs: "2.5rem", md: "4rem" },
              }}
            >
              פתיתים של שמחה
            </Typography>
            <Typography
              variant="h4"
              component="h2"
              sx={{
                mb: 4,
                fontSize: { xs: "1.5rem", md: "2rem" },
              }}
            >
              את יכולה להיות מאושרת
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 4,
                fontSize: { xs: "1rem", md: "1.25rem" },
                lineHeight: 1.5,
                textAlign: "center",
              }}
            >
              קורס דיגיטלי שיעזור לך למצוא שמחה ואושר בחייך. הצטרפי אלינו למסע
              של גילוי עצמי ושיפור איכות החיים. עם הדרכות מעשיות, תרגילים
              מעמיקים, וכלים אפקטיביים, תוכלי להתחבר לשמחה הפנימית שלך ולהוביל
              את חייך לכיוון חיובי ומספק יותר.
            </Typography>
            <Button
              variant="contained"
              color="info"
              size="large"
              href="https://nivdigital.co.il/courses/פתיתים-של-שמחה-את-יכולה-להיות-מאושרת-ד/"
              target="_blank"
              sx={{
                px: 5,
                py: 1.5,
                fontSize: "1.2rem",
                textTransform: "none",
              }}
            >
              להרשמה לקורס
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            py={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card
              sx={{
                width: 480,
              }}
            >
              <CardMedia
                component="iframe"
                title={"פתיתים של שמחה - דאלי הדר מגל - אביב בחיים"}
                src={
                  "https://www.youtube.com/embed/ID-1ORHLLc8?si=dDu59j_RgQSXldnK"
                }
                height={270}
                frameBorder="0"
                allowFullScreen
              />
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CourseShowcase;
