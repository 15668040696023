import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoAndContactInfo from "./footer/LogoAndContactInfo";
import SocialMedia from "./footer/SocialMedia";
import Body from "./common/Body";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "50vh",
        backgroundColor: "#00322b",
        color: "#fff",
        margin: 0,
      }}
    >
      <LogoAndContactInfo />
      <SocialMedia />
      <Grid
        xs={12}
        container
        item
        justifyContent="center"
        alignItems="center"
        py={5}
      >
        <Body>{t("footer.allRightsReserved")}</Body>
      </Grid>
    </Grid>
  );
};

export default Footer;
