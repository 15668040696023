import React from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import bookletImage from "../../assets/bookletImage-min.jpeg";
import CustomButton from "../common/CustomButton";
import { OpenInNew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Booklet = ({ link }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          paddingY: 20,
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            md={8}
            px={2}
            container
            item
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="h3"
              gutterBottom
              style={{ color: palette.error.main, fontWeight: 900 }}
            >
              {t("booklet.title")}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {t("booklet.description1")}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {t("booklet.description2")}
            </Typography>
            <Box paddingY={3} width={"100%"}>
              <CustomButton
                fullWidth
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(link);
                }}
              >
                <OpenInNew />
                <Typography variant="h4" paddingX={2}>
                  {t("booklet.button")}
                </Typography>
              </CustomButton>
            </Box>
          </Grid>
          <Grid
            md={4}
            px={2}
            container
            item
            justifyContent="flex-start"
            alignItems="center"
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: 300, md: 500 },
                borderRadius: 2,
                backgroundImage: `url(${bookletImage})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Booklet;
