import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Button, Collapse } from "@mui/material";
import { FormatQuote } from "@mui/icons-material";
import SubTitle from "../common/SubTitle";
import Body from "../common/Body";
import { useTranslation } from "react-i18next";

const RecommendationCard = ({ name, description, expandText }) => {
  const [expand, setExpend] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Box px={2} py={10}>
      <Card dir="rtl" sx={{ width: "100%" }} elevation={6}>
        <CardContent sx={{ padding: { xs: 3, md: 5 } }}>
          <SubTitle align="center">{name}</SubTitle>
          <SubTitle align="right">
            <FormatQuote color="warning" fontSize="inherit" />
          </SubTitle>
          <Body>{description}</Body>
          {expandText && (
            <>
              <Button
                fullWidth
                onClick={() => {
                  setExpend((prev) => !prev);
                }}
              >
                <Body>
                  {expand ? t("common.readLess") : t("common.readMore")}
                </Body>
              </Button>
              <Collapse in={expand} timeout="auto" unmountOnExit>
                <Body>{expandText}</Body>
              </Collapse>
            </>
          )}
          <SubTitle align="left">
            <FormatQuote color="warning" fontSize="inherit" />
          </SubTitle>
          <Box height={50} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default RecommendationCard;
