import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import TitleAndStoreLinks from "../../components/app/TitleAndStoreLinks";
import { useTranslation } from "react-i18next";
import { SportsEsports } from "@mui/icons-material";
import ScreenShotsCarousel from "./ScreenShotsCarousel";
import { ROUTES } from "../../utils/constants";
import { useNavigate } from "react-router";
import Title from "../../components/common/Title";
import { appScreens } from "../../utils";

const HomeGameShowcase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Box pt={10}>
          <Title>{t("app.title")}</Title>
        </Box>
        <Grid container spacing={2} py={2}>
          <TitleAndStoreLinks>
            <Typography>{t("app.description")}</Typography>
            <Button
              size={"large"}
              startIcon={<SportsEsports />}
              color={"warning"}
              variant={"outlined"}
              onClick={() => {
                navigate(ROUTES.APP);
              }}
            >
              <Typography variant={"h6"} px={2}>
                {t("app.moreInfo")}
              </Typography>
            </Button>
          </TitleAndStoreLinks>
          <Grid
            item
            container
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <ScreenShotsCarousel screenshots={appScreens} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HomeGameShowcase;
