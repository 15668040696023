import React from "react";
import Card from "@mui/material/Card";
import { CardMedia, Grid, Typography, useTheme } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import useWindowDimensions from "../../utils/useWindowDimensions";

const YoutubeCard = ({ title, videoId }) => {
  // Construct the YouTube embed URL
  const videoUrl = `https://www.youtube.com/embed/${videoId}`;
  const { height } = useWindowDimensions();
  const theme = useTheme();

  return (
    <Grid item xs={12} md={6}>
      <Card
        sx={{
          width: "100%",
          boxShadow: 3,
          borderRadius: "10px",
          overflow: "hidden",
          maxHeight: "600px",
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
            textAlign="center"
            style={{ color: theme.palette.secondary.main, fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </CardContent>
        <CardMedia
          component="iframe"
          title={title}
          src={videoUrl}
          height={height / 2}
          frameBorder="0"
          allowFullScreen
        />
      </Card>
    </Grid>
  );
};

export default YoutubeCard;
