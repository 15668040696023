import React from "react";
import { Typography } from "@mui/material";

const Body = ({ children, align = "center" }) => {
  return (
    <Typography
      variant="h6"
      gutterBottom
      style={{
        textAlign: align,
        fontWeight: "normal",
        margin: 0,
      }}
    >
      {children}
    </Typography>
  );
};

export default Body;
