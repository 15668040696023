import React from "react";
import Carousel from "react-multi-carousel";
import { Box, Button, Container, IconButton, Paper } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { GENERAL } from "../../utils/constants";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Circle,
  CircleOutlined,
} from "@mui/icons-material";

const ScreenShotsCarousel = ({ screenshots }) => {
  const { width } = useWindowDimensions();
  const mobile = width < GENERAL.MAX_MOBILE_WIDTH;
  const imageWidth = mobile ? 250 : 350;

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      // onMove,
      index,
      active,
      // carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return active ? (
      <IconButton color="secondary" onClick={() => onClick()}>
        <Circle key={index} color="inherit" />
      </IconButton>
    ) : (
      <IconButton color="secondary" onClick={() => onClick()}>
        <CircleOutlined key={index} color="inherit" />
      </IconButton>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <Button
        color="secondary"
        onClick={() => onClick()}
        sx={{
          position: "absolute",
          left: 0,
          height: { xs: 50, md: "100%" },
        }}
      >
        <ArrowBackIos />
      </Button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <Button
        color="secondary"
        onClick={() => onClick()}
        sx={{
          position: "absolute",
          right: 0,
          height: { xs: 50, md: "100%" },
        }}
      >
        <ArrowForwardIos />
      </Button>
    );
  };

  return (
    <Container>
      {screenshots && (
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className="center-carousel-main"
          containerClass="container-with-dots"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={10}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 0,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          slidesToSlide={1}
          swipeable
          customDot={<CustomDot />}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {screenshots?.map((screen) => {
            return (
              <Box key={screen} pb={5}>
                <Paper
                  elevation={6}
                  sx={{
                    borderRadius: 3,
                    margin: 0,
                    padding: 0,
                    width: imageWidth,
                    height: imageWidth * 2.164,
                    backgroundImage: `url(${screen})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                  }}
                ></Paper>
              </Box>
            );
          })}
        </Carousel>
      )}
    </Container>
  );
};

export default ScreenShotsCarousel;
