import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({
  children,
  variant = "contained",
  color = "primary",
  onClick = () => {},
  fullWidth = false,
  size = "medium",
  type = "button",
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      style={{
        textTransform: "unset",
      }}
      onClick={onClick}
      fullWidth={fullWidth}
      size={size}
      type={type}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
