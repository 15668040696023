import React from "react";
import { Typography } from "@mui/material";

const SubTitle = ({ children, align = "center" }) => {
  return (
    <Typography
      variant="h4"
      gutterBottom
      style={{
        textAlign: align,
        margin: 0,
      }}
    >
      {children}
    </Typography>
  );
};

export default SubTitle;
