import React from "react";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Body from "../common/Body";
import {
  Facebook,
  Instagram,
  LocationOn,
  MailOutline,
  PhoneIphoneRounded,
} from "@mui/icons-material";
import CustomButton from "../common/CustomButton";
import Title from "../common/Title";

const SocialMedia = () => {
  const { t } = useTranslation();
  const PhoneButton = () => {
    return (
      <CustomButton
        variant="text"
        color="inherit"
        fullWidth={true}
        size={"large"}
        onClick={() => {
          window.open(`tel:${t("header.phoneNumberLink")}`, "_self");
        }}
      >
        <PhoneIphoneRounded color="info" sx={{ mx: 1 }} />
        <Body>{t("header.phoneNumberText")}</Body>
      </CustomButton>
    );
  };

  const MailButton = () => {
    return (
      <CustomButton
        variant="text"
        color="inherit"
        fullWidth={true}
        size={"large"}
        onClick={() => {
          window.open(`mailto:${t("header.mailLink")}`, "_self");
        }}
      >
        <MailOutline color="info" sx={{ mx: 1 }} />
        <Body>{t("header.mailLink")}</Body>
      </CustomButton>
    );
  };

  return (
    <Grid
      md={6}
      container
      item
      justifyContent="center"
      alignItems="center"
      sx={{ px: 1, pt: 10 }}
    >
      <Grid
        xs={12}
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{ p: 1 }}
      >
        <Box sx={{ py: 2 }}>
          <Title color="inherit">{t("footer.contactUs")}</Title>
          <Box height={10} />
          <PhoneButton />
          <Box height={10} />
          <MailButton />
          <Box height={10} />
          <CustomButton
            variant="text"
            fullWidth
            color="inherit"
            onClick={() => {
              window.open(t("footer.googleMapsLink"));
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Body>
                <LocationOn color="info" sx={{ mx: 1 }} />
                {t("footer.address")}
              </Body>
            </Box>
          </CustomButton>
        </Box>
      </Grid>
      <Grid xs={12} container item justifyContent="center" alignItems="center">
        <Box sx={{ py: 2 }}>
          <CustomButton
            color="inherit"
            size="large"
            variant="text"
            onClick={() => {
              window.open(t("footer.facebookLink"));
            }}
          >
            <Facebook color="inherit" fontSize="large" />
          </CustomButton>
          <CustomButton
            color="inherit"
            size="large"
            variant="text"
            onClick={() => {
              window.open(t("footer.instagramLink"));
            }}
          >
            <Instagram color="inherit" fontSize="large" />
          </CustomButton>
        </Box>
      </Grid>
      <Grid xs={12} container item justifyContent="center" alignItems="center">
        <Body>
          {t("footer.mediaText")} {t("footer.media")}
        </Body>
      </Grid>
    </Grid>
  );
};

export default SocialMedia;
