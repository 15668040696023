import React from "react";
import { Box, Container } from "@mui/material";
import Title from "../common/Title";
import SubTitle from "../common/SubTitle";
import Body from "../common/Body";
import RoseDivider from "../common/RoseDivider";
import me from "../../assets/me-min.jpeg";
import { useTranslation } from "react-i18next";

const AboutMe = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingY: 5,
        paddingX: 2,
      }}
    >
      <Box>
        <Title>{t("me.title")}</Title>
        <Box display="flex" flexDirection="row-reverse" flexWrap="wrap">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            py={5}
            minWidth={300}
            minHeight={300}
          >
            <SubTitle align="center">{t("me.subTitle1")}</SubTitle>
            <Body align="center">{t("me.subTitle2")}</Body>
            <Box height={20} />
            <RoseDivider />
            <Box height={20} />
            <Body align="right">{t("me.textMain")}</Body>
            <Box height={20} />
            <Body align="right">{t("me.textExpend1")}</Body>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            py={5}
          >
            <Box
              sx={{
                width: { xs: 300, md: 500 },
                height: { xs: 300, md: 500 },
                borderRadius: 2,
                backgroundImage: `url(${me})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutMe;
