import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ContactContainer from "../components/contact/ContactContainer";
import RecommendationsContainer from "../components/recomendation/RecommendationsContainer";
import MyStory from "../components/aboutMe/MyStory";
import AboutSpring from "../components/aboutSpring/AboutSpring";
import ServicesContainer from "../components/services/ServicesContainer";
import WomenBlessingContainer from "../components/womenBlessing/WomenBlessingContainer";
import AboutMe from "../components/aboutMe/AboutMe";
import Booklet from "../components/booklet/Booklet";
import { Dialog, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import HomeGameShowcase from "./app/HomeGameShowcase";
import ProductsContainer from "../components/products/ProductsContainer";
import SpotifyPodcast from "../components/SpotifyPodcast";
import YoutubeContainer from "../components/youtube/YoutubeContainer";
import AmazonBookShowcase from "../components/amazon/AmazonBookShowcase";
import CourseShowcase from "../components/course/CourseShowcase";

const homeLink = "https://springinmylife.ravpage.co.il/booklet?ref=homepage";
const popupLink = "https://springinmylife.ravpage.co.il/booklet?ref=POPUP";

const Home = () => {
  const { t } = useTranslation();

  // modal pops up on first page load
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <WomenBlessingContainer />
      <YoutubeContainer />
      <CourseShowcase />
      <AmazonBookShowcase />
      <SpotifyPodcast />
      <HomeGameShowcase />
      <Booklet link={homeLink} />
      <AboutMe />
      <MyStory />
      <AboutSpring />
      <ProductsContainer />
      <ServicesContainer />
      <RecommendationsContainer />
      <ContactContainer />
      {open && (
        <Dialog
          fullWidth={true}
          maxWidth={"xl"}
          open={open}
          onClose={handleClose}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <Booklet link={popupLink} />
        </Dialog>
      )}
    </>
  );
};

export default Home;
