import React from "react";
import { Box, Container, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CenterCarousel from "../common/CenterCarousel";
import RecommendationCard from "./RecommendationCard";
import Title from "../common/Title";

const RecommendationsContainer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, ${theme.palette.background.default} 25%, ${theme.palette.primary.main} 100%)`,
      }}
    >
      <Box height={100} />
      <Container>
        <Title>{t("recommendations.title")}</Title>
        <CenterCarousel>
          <RecommendationCard
            name={t("recommendations.recommendation1.name")}
            description={t("recommendations.recommendation1.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation2.name")}
            description={t("recommendations.recommendation2.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation3.name")}
            description={t("recommendations.recommendation3.text")}
            expandText={t("recommendations.recommendation3.expendText")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation4.name")}
            description={t("recommendations.recommendation4.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation5.name")}
            description={t("recommendations.recommendation5.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation6.name")}
            description={t("recommendations.recommendation6.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation7.name")}
            description={t("recommendations.recommendation7.text")}
          />
          <RecommendationCard
            name={t("recommendations.recommendation8.name")}
            description={t("recommendations.recommendation8.text")}
          />
        </CenterCarousel>
      </Container>
      <Box height={100} />
    </Box>
  );
};

export default RecommendationsContainer;
