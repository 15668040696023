import React from "react";
import { Container, Typography } from "@mui/material";
import HeightSpacer from "./HeightSpacer";

const Agreement = ({ title, content }) => {
  return (
    <Container>
      <HeightSpacer />
      <Typography variant="h4" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {content}
      </Typography>
    </Container>
  );
};

export default Agreement;
