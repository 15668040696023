import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Button, IconButton } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Circle,
  CircleOutlined,
} from "@mui/icons-material";

const CenterCarousel = ({ children }) => {
  const { width } = useWindowDimensions();

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      // onMove,
      index,
      active,
      // carouselState: { currentSlide, deviceType },
    } = rest;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return active ? (
      <IconButton color="secondary" onClick={() => onClick()}>
        <Circle key={index} color="inherit" />
      </IconButton>
    ) : (
      <IconButton color="secondary" onClick={() => onClick()}>
        <CircleOutlined key={index} color="inherit" />
      </IconButton>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <Button
        color="secondary"
        onClick={() => onClick()}
        sx={{
          position: "absolute",
          left: 0,
          height: { xs: 50, md: "100%" },
        }}
      >
        <ArrowBackIos />
      </Button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <Button
        color="secondary"
        onClick={() => onClick()}
        sx={{
          position: "absolute",
          right: 0,
          height: { xs: 50, md: "100%" },
        }}
      >
        <ArrowForwardIos />
      </Button>
    );
  };

  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={width > 800}
      className="center-carousel-main"
      containerClass="container-with-dots"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
          partialVisibilityGutter: 10,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={true}
      sliderClass=""
      slidesToSlide={1}
      swipeable
      customDot={<CustomDot />}
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
    >
      {children}
    </Carousel>
  );
};

export default CenterCarousel;
