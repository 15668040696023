import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Title from "../../components/common/Title";

import { Store } from "@mui/icons-material";
import ScreenShotsCarousel from "../../screens/app/ScreenShotsCarousel";
import { amazonBookImages } from "../../utils";
import HeightSpacer from "../common/HeightSpacer";

const AmazonBookShowcase = () => {
  const { palette } = useTheme();

  return (
    <>
      <Container>
        <Box pt={10}>
          <Title>Lessons in Happiness - Amazon</Title>
        </Box>
        <Grid container spacing={2} py={2} flexDirection={"row-reverse"}>
          <Grid
            item
            container
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Paper
              elevation={6}
              sx={{
                width: "100%",
                height: "100%",
                padding: 2,
                backgroundColor: palette.primary.main,
                color: "white",
              }}
            >
              <Grid item xs={12}>
                <Box
                  component={"img"}
                  src={
                    "https://m.media-amazon.com/images/S/amzn-author-media-prod/nrb46bssphpaj7hvhf8cbt8r14._SX300_CR0%2C0%2C300%2C300_.jpg"
                  }
                  alt={"logo"}
                  width={150}
                  borderRadius={4}
                />
              </Grid>
              <Typography variant="h5">
                Easy, Simple, Everyday Techniques for a Life of Gratitude, Inner
                Strength, and Emotional Intelligence
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Discover the transformative power of Dali Magal’s simple,
                everyday techniques for true happiness—no matter what challenges
                you face.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>What can we learn from grief?</strong>
                <br />
                <strong>
                  What is the greatest gift you can give yourself?
                </strong>
                <br />
                <strong>How do we say “thank you” – and mean it?</strong>
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                After the tragic loss of her husband, Dali Magal felt like she’d
                lost everything. A widow at twenty-five with two young children,
                Magal sank into a deep depression that threatened to swallow her
                whole. Happiness wasn’t just a memory—it was an impossible task.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                <em>Lessons in Happiness</em> is an empowering daily curriculum
                for finding purpose and gratitude in life, even when the odds
                seem stacked against you. In an extraordinary mix of research
                and storytelling, Magal shares the lessons she learned on her
                thirty-year journey back into happiness—from finding the courage
                to follow your dreams as a single mother to relearning to
                receive love and affection from your surroundings, your
                family—and from yourself.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2, fontStyle: "italic" }}>
                “We don’t always need a long, complicated process to bounce back
                from a crisis,” Magal says. “Sometimes, all we need are small
                reminders of happiness.”
              </Typography>
              <HeightSpacer height={16} />
              <Button
                size={"large"}
                startIcon={<Store />}
                color={"warning"}
                variant={"outlined"}
                href="https://www.amazon.com/dp/B0DG5YJXCR"
                target="_blank"
                rel="noopener"
              >
                <Typography variant={"h6"} px={2}>
                  Buy on Amazon
                </Typography>
              </Button>
            </Paper>
          </Grid>
          <Grid
            item
            container
            md={6}
            justifyContent={"center"}
            alignItems={"center"}
            textAlign={"center"}
          >
            <ScreenShotsCarousel screenshots={amazonBookImages} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AmazonBookShowcase;
