import React from "react";
import { Box, Container } from "@mui/material";
import Title from "../common/Title";
import lectures from "../../assets/services/lectures-min.JPG";
import groupSessions from "../../assets/services/group-sessions-min.JPG";
import kit from "../../assets/services/kit-min.JPG";
import oneOnOne from "../../assets/services/one-on-one-min.JPG";
import { useTranslation } from "react-i18next";
import ServicesCard from "./ServicesCard";
import Body from "../common/Body";

const ServicesContainer = () => {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingY: 10,
        paddingX: 2,
      }}
    >
      <Box flexGrow={1}>
        <Title>{t("services.title")}</Title>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          flexGrow={1}
        >
          <ServicesCard
            title={t("services.lectures.title")}
            text={t("services.lectures.textMain")}
            button={t("services.lectures.button")}
            image={lectures}
            link={t("whatsapp.lectures")}
          />
          <ServicesCard
            title={t("services.workshops.title")}
            text={t("services.workshops.textMain")}
            button={t("services.workshops.button")}
            image={groupSessions}
            link={t("whatsapp.workshops")}
          >
            <Box height={10} />
            <Body align="right">{t("services.workshops.textExpend1")}</Body>
            <Box height={10} />
            <Body align="right">{t("services.workshops.textExpend2")} </Body>
          </ServicesCard>
          <ServicesCard
            title={t("services.personalSessions.title")}
            text={t("services.personalSessions.textMain")}
            button={t("services.personalSessions.button")}
            image={oneOnOne}
            link={t("whatsapp.personalSessions")}
          >
            <Box height={10} />
            <Body align="right">
              {t("services.personalSessions.textExpend1")}
            </Body>
            <Box height={10} />
            <Body>
              <strong>{t("services.personalSessions.textExpend2")}</strong>
            </Body>
          </ServicesCard>
          <ServicesCard
            title={t("services.products.title")}
            text={t("services.products.textMain")}
            button={t("services.products.button")}
            image={kit}
            link={t("whatsapp.products")}
          >
            <Box height={10} />
            <Body align="right">{t("services.products.textExpend1")}</Body>
            <Box height={10} />
            <Body align="right">{t("services.products.textExpend2")} </Body>
            <Box height={10} />
            <Body align="right">
              <strong>{t("services.products.textExpend3")}</strong>
            </Body>
            <Box height={10} />
            <Body align="right">
              <strong>{t("services.products.textExpend4")}</strong>
            </Body>
          </ServicesCard>
        </Box>
      </Box>
    </Container>
  );
};

export default ServicesContainer;
