export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/*",
  APP: "/app",
  PRIVACY: "/app/privacy-policy",
  TERMS: "/app/terms-of-use",
  EULA: "/app/eula",
};

export const GENERAL = {
  EMPTY_STRING: "",
  MAX_MOBILE_WIDTH: 600,
  APP: { TITLE: "Feelings App", SUB: "Spring In My Life" },
};

export const SECTIONS = {
  LANGUAGES: "languages",
  DEVICES: "devices",
  POSITIVE: "positive",
  NEGATIVE: "negative",
  QUESTIONS: "questions",
  EXPLORE: "explore",
};

export const LINKS = {
  APP_STORE: "https://apps.apple.com/us/app/id6471379393",
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.chaimagal.thefeelingsgame",
};

export const LANGUAGES = {
  English: {
    CODE: "en",
    VALUE: "English",
  },
  Hebrew: {
    CODE: "he",
    VALUE: "עברית",
  },
};

export const LANGUAGES_ARR = [
  {
    CODE: "en",
    VALUE: "English",
  },
  {
    CODE: "he",
    VALUE: "עברית",
  },
];
