import React from "react";
import { Button } from "@mui/material";
import Lottie from "lottie-react";

const FeatureButton = ({ section, src, text }) => {
  return (
    <Button
      variant={"outlined"}
      sx={{
        textTransform: "capitalize",
        flexDirection: "column",
        padding: 2,
        width: "100%",
      }}
      href={section}
    >
      <Lottie
        animationData={src}
        loop={true}
        style={{ width: 100, height: 100 }}
      />
      {text}
    </Button>
  );
};

export default FeatureButton;
