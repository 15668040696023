import React from "react";
import { Box, Button, Collapse, Container } from "@mui/material";
import Title from "../common/Title";
import Body from "../common/Body";
import aboutSpring from "../../assets/about-spring-min.png";
import { useTranslation } from "react-i18next";
import SubTitle from "../common/SubTitle";
import RoseDivider from "../common/RoseDivider";

const AboutSpring = () => {
  const { t } = useTranslation();
  const [expand, setExpend] = React.useState(false);
  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingY: 5,
        paddingX: 2,
      }}
    >
      <Box>
        <Title>{t("aboutSpring.title")}</Title>
        <Box display="flex" flexDirection="row" flexWrap="wrap-reverse">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            py={5}
          >
            <Box
              sx={{
                width: { xs: 300, md: 500 },
                height: { xs: 300, md: 500 },
                borderRadius: 2,
                backgroundImage: `url(${aboutSpring})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            minWidth={300}
            minHeight={300}
            py={5}
          >
            <SubTitle align="center">
              <strong></strong>
              {t("aboutSpring.subtitle1")}
            </SubTitle>
            <Body align="center">{t("aboutSpring.subtitle2")}</Body>
            <Box height={20} />
            <RoseDivider />
            <Box height={20} />
            <Body align="center">{t("aboutSpring.textMain")}</Body>
            <Box height={20} />
            <Button
              onClick={() => {
                setExpend((prev) => !prev);
              }}
            >
              <Body>
                {expand ? t("common.readLess") : t("common.readMore")}
              </Body>
            </Button>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Box height={20} />
              <Body align="right">{t("aboutSpring.textExpend1")}</Body>
              <Box height={20} />
              <Body align="right">{t("aboutSpring.textExpend2")}</Body>
              <Box height={20} />
              <Body align="right">
                <strong>{t("aboutSpring.textExpend3")}</strong>
              </Body>
              <Box height={20} />
              <Body align="right">{t("aboutSpring.textExpend4")}</Body>
              <Box height={20} />
              <Body align="right">{t("aboutSpring.textExpend5")}</Body>
              <Box height={20} />
              <Body align="right">{t("aboutSpring.textExpend6")}</Body>
            </Collapse>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AboutSpring;
