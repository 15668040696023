import React from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { GENERAL } from "../../utils/constants";
import Lottie from "lottie-react";

const Section = ({ lottie, direction = "row", title, text, image, id }) => {
  const { palette } = useTheme();
  const { width } = useWindowDimensions();
  const mobile = width < GENERAL.MAX_MOBILE_WIDTH;
  const gifWidth = mobile ? 100 : 300;
  const imageWidth = mobile ? 250 : 350;
  return (
    <Paper
      id={id}
      elevation={0}
      sx={{
        paddingX: 2,
        paddingY: 10,
        marginY: 10,
        borderRadius: 5,
        backgroundColor: palette.section.main,
        width: "100%",
      }}
    >
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={direction}
        flexWrap={"wrap"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        gap={5}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          maxWidth={300}
          textAlign={"center"}
        >
          <Lottie
            animationData={lottie}
            loop={true}
            style={{ width: gifWidth }}
          />
          <Typography variant={mobile ? "h4" : "h3"}>{title}</Typography>
          <Box height={16} />
          <Typography variant={mobile ? "body 1" : "h5"}>{text}</Typography>
        </Box>
        <Paper
          elevation={6}
          sx={{
            borderRadius: 3,
            margin: 0,
            padding: 0,
            width: imageWidth,
            height: imageWidth * 2.164,
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></Paper>
      </Box>
    </Paper>
  );
};

export default Section;
