import React from "react";
import "./App.scss";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import TopNav from "./components/TopNav";
import { Route, Routes } from "react-router";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import NotFound from "./screens/NotFound";
import { ROUTES } from "./utils/constants";
import { useTranslation } from "react-i18next";
import WhatsappFab from "./components/WhatsappFab";
import AppHome from "./screens/app/AppHome";
import PrivacyPolicy from "./screens/app/PrivacyPolicy";
import TermsOfUse from "./screens/app/TermsOfUse";
import Eula from "./screens/app/Eula";
import MrAnxietyFab from "./components/MrAnxietyFab";
import SpringBooksFab from "./components/SpringBooksFab";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00868d",
    },
    secondary: {
      main: "#d29c00",
    },
    error: {
      main: "#ff485d",
    },
    warning: {
      main: "#eae1a0",
    },
    success: {
      main: "#73a153",
    },
    info: {
      main: "#988d60",
    },
    background: {
      default: "#fffdfa",
    },
    section: {
      main: "#bed9ac",
    },
    black: { main: "#000000" },
  },
  typography: {
    fontFamily: "'Assistant','Times New Roman', sans-serif",
  },
});

function App() {
  const { t } = useTranslation();

  React.useEffect(() => {
    //change language direction to right-to-left
    document.dir = "rtl";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet>
        <title>{t("helmet.home")}</title>
      </Helmet>
      <TopNav />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />}></Route>
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
        <Route path={ROUTES.APP} element={<AppHome />} />
        <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.TERMS} element={<TermsOfUse />} />
        <Route path={ROUTES.EULA} element={<Eula />} />
      </Routes>
      <WhatsappFab />
      <MrAnxietyFab />
      <SpringBooksFab />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
