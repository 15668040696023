import React from "react";
import { Box, Button, Collapse, Container } from "@mui/material";
import Body from "../common/Body";
import Title from "../common/Title";
import { useTranslation } from "react-i18next";
import aboutMe from "../../assets/about-me-min.png";
import SubTitle from "../common/SubTitle";
import RoseDivider from "../common/RoseDivider";

const MyStory = () => {
  const { t } = useTranslation();

  const [expand, setExpend] = React.useState(false);

  return (
    <Container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingY: 5,
        paddingX: 2,
      }}
    >
      <Box>
        <Title>{t("aboutMe.title")}</Title>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex={1}
            py={5}
            minWidth={300}
            minHeight={300}
          >
            <SubTitle align="center">{t("aboutMe.subTitle1")}</SubTitle>
            <Body align="center">{t("aboutMe.subTitle2")}</Body>
            <Box height={20} />
            <RoseDivider />
            <Box height={20} />
            <Body align="center">{t("aboutMe.textMain")}</Body>
            <Box height={20} />
            <Button
              onClick={() => {
                setExpend((prev) => !prev);
              }}
            >
              <Body>
                {expand ? t("common.readLess") : t("common.readMore")}
              </Body>
            </Button>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Box height={20} />
              <Body align="right">{t("aboutMe.textExpend1")}</Body>
              <Box height={20} />
              <Body align="right">{t("aboutMe.textExpend2")}</Body>
              <Box height={20} />
              <Body align="right">
                <strong>{t("aboutMe.textExpend3")}</strong>
              </Body>
              <Box height={20} />
              <Body align="right">{t("aboutMe.textExpend4")}</Body>
              <Box height={20} />
              <Body align="right">
                <strong>{t("aboutMe.textExpend5")}</strong>
              </Body>
            </Collapse>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            py={5}
          >
            <Box
              sx={{
                width: { xs: 300, md: 500 },
                height: { xs: 300, md: 500 },
                borderRadius: 2,
                backgroundImage: `url(${aboutMe})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MyStory;
