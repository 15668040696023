import React from "react";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const WhatsappFab = () => {
  const { t } = useTranslation();

  const handleFab = () => {
    window.open(t("whatsapp.fab"));
  };
  return (
    <Fab
      onClick={handleFab}
      color="success"
      sx={{
        position: "fixed",
        bottom: 50,
        left: 0,
        borderRadius: 0,
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50,
      }}
    >
      <WhatsApp />
    </Fab>
  );
};

export default WhatsappFab;
