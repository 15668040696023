import React from "react";
import { useTranslation } from "react-i18next";
import Section from "../common/Section";
import { SECTIONS } from "../../utils/constants";
import { urlFromImageId } from "../../utils";

//lotties
import devices from "../../assets/lottie/devices.json";
import question from "../../assets/lottie/question.json";
import happy from "../../assets/lottie/happy.json";
import sad from "../../assets/lottie/sad.json";
import explore from "../../assets/lottie/explore.json";
import languages from "../../assets/lottie/languages.json";
import { Container } from "@mui/material";

//screenshots
const devicesScreen = urlFromImageId("1Mk45oHzcJ_E22w-zyio5zKRvI7Wx0Zc1");
const questionScreen = urlFromImageId("1EnRpuQvKqRDKXhxZYuxLtAKEwYie3zEf");
const happyScreen = urlFromImageId("1WNwzWvjn3Al_zTvJ8t28yM1_VsA2Qs8I");
const sadScreen = urlFromImageId("1i6MZOaXmrm43fVGwvBh9yPNA1DdKabAD");
const exploreScreen = urlFromImageId("13557rAPIbeLX4x2eI8Oy7fZAvPtBB0iA");
const languagesScreen = urlFromImageId("1zkPYxelGdtCBOECIkMO5Fh0ZuUaLItQj");

const Sections = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Section
        id={SECTIONS.DEVICES}
        lottie={devices}
        image={devicesScreen}
        title={t("sections.multipleDevices.title")}
        text={t("sections.multipleDevices.text")}
      />
      <Section
        id={SECTIONS.QUESTIONS}
        lottie={question}
        image={questionScreen}
        title={t("sections.questions.title")}
        text={t("sections.questions.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.POSITIVE}
        lottie={happy}
        image={happyScreen}
        title={t("sections.positive.title")}
        text={t("sections.positive.text")}
      />
      <Section
        id={SECTIONS.NEGATIVE}
        lottie={sad}
        image={sadScreen}
        title={t("sections.negative.title")}
        text={t("sections.negative.text")}
        direction={"row-reverse"}
      />
      <Section
        id={SECTIONS.EXPLORE}
        lottie={explore}
        image={exploreScreen}
        title={t("sections.explore.title")}
        text={t("sections.explore.text")}
      />
      <Section
        id={SECTIONS.LANGUAGES}
        lottie={languages}
        image={languagesScreen}
        title={t("sections.languages.title")}
        text={t("sections.languages.text")}
        direction={"row-reverse"}
      />
    </Container>
  );
};

export default Sections;
